import React from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import Success from './json/success'
import Spinner from './json/spinner'
import Warning from './json/warning'

const defaultOptions = (animationData) => ({
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid',
  },
})

function Animation({ name, width, height, ...options }) {
  const animationData = animations[name] || null
  return (
    <div className="pointer-events-none">
      <Lottie options={{ ...defaultOptions(animationData), ...options }} width={width} height={height} />
    </div>
  )
}

const animations = {
  success: Success,
  spinner: Spinner,
  warning: Warning,
}

Animation.defaultProps = {
  height: 400,
  width: 400,
  name: PropTypes.oneOf(['success', 'spinner', 'warning']),
}

export default Animation
